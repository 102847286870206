<template>
  <div class="mainSlider hyundai">
    <!-- v-if="mainSlider || slider" -->
    <vueper-slides v-if="slider"
                   :autoplay="slider.length > 1 ? autoplay : null"
                   :duration="5000" :draging-distance="90" :Prevent-y-scroll="true"
                   :touchable="!autoplay" :infinite="true" :arrows="false"
                   :pause-on-hover="false" :pause-on-touch="false">
      <!-- 경기 & 일반 슬라이더 -->
      <vueper-slide v-for="(item, index) in slider" :key="index"
                    :style="{
                background: 'rgba(0, 0, 0, 0.5) url(' + item.setImg + ')',
                height: '100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundBlendMode: 'darken'
              }">
        >
        <template #content v-if="item.target_game !== ''">
          <div class="sliderContent">
            <div class="inner">
              <div class="game gmarket">
                {{ item.target_game.home_team_name }}
                <span class="vs">VS</span>
                {{ item.target_game.away_team_name }}
              </div>
              <div class="date">{{ item.target_game.game_date }} {{ item.target_game.game_time }}</div>
              <Countdown :end-date="item.target_game.game_date_iso" :label="true" :label-color="'#ffffff'"/>
              <!--<router-link :to="{name: 'main'}" class="btn yellow large">예매하기</router-link>-->
              <a :href="item.link" target="item.target" class="btn yellow large btnReservation">예매하기</a>
            </div>
          </div>

        </template>
        <template #content v-else>
          <a :href="item.link" :target="item.target" class="sliderContent" style="display:block;">
            <div class="inner">
              <div><span class="category">{{item.category}}</span></div>
              <div class="title">{{item.title}}</div>
              <div v-if="item.link" class="sliderBtn">
                <a :href="item.link" target="_self" class="btn green large btnEvent">기사보기</a>
              </div>
            </div>
          </a>
        </template>
      </vueper-slide>
      <!-- //일반 -->
      <!-- 슬라이더 진행바 -->
      <template #bullets="{ bulletIndexes, goToSlide, previous, next, currentSlide }">
        <button v-for="(bullets, i) in sliderBullets" :key="i" class="btnBullet" role="tab"
                :class="{ 'active': currentSlide === i }" :aria-label="`Slide ${i + 1}`" @click="goToSlide(i)"
                @keyup.left="previous()" @keyup.right="next()">
          <span class="index">
            {{ `${('0' + (i + 1)).slice(-2)}` }}
          </span>
          <div class="progress">
            <div class="bar"></div>
          </div>
          <div class="txt">
            {{ bullets }}
          </div>
        </button>
      </template>
      <!-- //슬라이더 진행바 -->
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Countdown from '@/components/Util/Countdown'
import http from '@/api/index'
import { unescape } from 'html-escaper'

export default {
  name: 'MainSlider',
  components: {
    VueperSlides,
    VueperSlide,
    Countdown
  },
  watch: {
    windowWidth(){
      this.slider.map(el => {
        this.windowWidth <= 768 ? el.setImg = el.img_mobile : el.setImg = el.img;
        if (el.setImg === null || '') {
          el.setImg = el.img
        }
      })
    }
  },
  computed: {
    sliderBullets() {
      let bullets = []
      const slider = this.slider
      // bullets.push(this.mainSlider.title)
      for (let i = 0; i < slider.length; i++) {
        bullets.push(slider[i].title)
      }
      return bullets
    }
  },
  created() {
    this.init()
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      mainSlider: null,
      slider: null,
      autoplay: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async init() {
      const sliderContent_res = await http.get('/main/main_slide')

      sliderContent_res.data.data.map(row => {
        row.setImg = ''
        this.windowWidth <= 768 ? row.setImg = row.img_mobile : row.setImg = row.img;
        this.windowWidth <= 768 ? this.autoplay = false : this.autoplay = true
        if (row.setImg === null || '') {
          row.setImg = row.img
        }
        if (row.target_game !== '') {
          row.target_game.game_time = row.target_game.game_time.slice(0, 5)
        }
        if (row.link_type === ''){
          row.target = '_self'
        }
        row.title = unescape(row.title).replaceAll("&#039;", '\'')
      })
      this.slider = sliderContent_res.data.data
    }
  }
}
</script>
<style lang="scss" scoped>
.large {
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  &.btnReservation:before {
    background-image: url('~@/assets/img/ico_ticket.png');
  }
  &.btnEvent:before {
    background-image: url('~@/assets/img/ico_cursor.png');
  }
}

</style>
